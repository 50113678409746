@use '../vdw-variables' as *;

.no-wrap {
  white-space: nowrap;
}

.inline-flex {
  display: inline-flex;
}

.no-margin {
  margin: 0 !important;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.two-columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.half-width {
  width: 50%;
}

.one-third-width {
  width: calc(100% / 3);
}

.two-third-width {
  width: calc(100% / 3) * 2;
}

.min-width-0 {
  min-width: 0;
}

.min-height-0 {
  min-height: 0;
}

.height-fit-content {
  height: fit-content;
}

.half-height {
  height: 50%;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.full-size {
  width: 100%;
  height: 100%;
}

.full-width-mobile {
  @include breakpoint-xsmall() {
    width: 100%;
  }
}

.full-max-width {
  max-width: 100%;
}

.full-min-height {
  min-height: 100%;
}

.full-max-height {
  max-height: 100%;
}

.flex {
  display: flex;
}

.flex-1 {
  display: flex;
  flex: 1;
}

.flex-0 {
  flex: 1 1 0;
}

.flex-50 {
  flex: 1 1 50%;
}

.flex-100 {
  flex: 1 1 100%;
}

.flex-grow {
  min-height: 0;
  flex-grow: 1;
}

.flex-no-grow {
  min-height: 0;
  flex-grow: 0;
}

.flex-row {
  display: flex !important;
  flex-direction: row !important;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-center-start {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-start-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-start-end {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.flex-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-end-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-space-evenly {
  display: flex;
  place-content: stretch space-evenly;
  align-items: stretch;
}

.flex-row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-no-shrink {
  flex-shrink: 0;
}

.no-gap {
  gap: unset;
}

.gap-2 {
  gap: 2px;
}

.gap-4 {
  gap: 4px;
}

.gap-6 {
  gap: 6px;
}

.gap-8 {
  gap: 8px !important;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.gap-24 {
  gap: 24px;
}

.gap-32 {
  gap: 32px;
}

.gap-40 {
  gap: 40px;
}

.gap-48 {
  gap: 48px;
}

.gap-64 {
  gap: 64px;
}

.m-12 {
  margin: 12px;
}

.ml-auto {
  margin-left: auto;
}

.mt-24 {
  margin-top: 24px;
}

.ml-24 {
  margin-left: 24px;
}

.mr-24 {
  margin-right: 24px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mt-16 {
  margin-top: 16px;
}

.ml-16 {
  margin-left: 16px;
}

.mr-16 {
  margin-right: 16px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mt-8 {
  margin-top: 8px;
}

.ml-8 {
  margin-left: 8px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-4 {
  margin-right: 4px;
}

.p-48 {
  padding: 48px;
}

.p-32 {
  padding: 32px;
}

.p-24 {
  padding: 24px;
}

.p-16 {
  padding: 16px;
}

.p-8 {
  padding: 8px;
}

.pr-64 {
  padding-right: 64px;
}

.pl-48 {
  padding-left: 48px;
}

.pr-32 {
  padding-right: 32px;
}

.pl-28 {
  padding-left: 28px;
}

.pt-24 {
  padding-top: 24px;
}

.pb-24 {
  padding-bottom: 24px;
}

.pl-24 {
  padding-left: 24px;
}

.pr-24 {
  padding-right: 24px;
}

.pb-16 {
  padding-bottom: 16px;
}

.pt-16 {
  padding-top: 16px !important;
}

.pl-16 {
  padding-left: 16px;
}

.pr-16 {
  padding-right: 16px;
}

.pb-8 {
  padding-bottom: 8px;
}

.pt-8 {
  padding-top: 8px;
}

.pl-8 {
  padding-left: 8px;
}

.pr-8 {
  padding-right: 8px;
}

.pb-4 {
  padding-bottom: 4px;
}

.pt-4 {
  padding-top: 4px;
}

.hidden {
  display: none !important;
}

.visibility-hidden {
  visibility: hidden !important;
}

.vertical-align-middle {
  vertical-align: middle;
}

.border-none {
  border: none !important;
}

.border-bottom {
  border-bottom: 1px solid $tint-darkblue-20 !important;
}

.border-top {
  border-top: 1px solid $tint-darkblue-20 !important;
}

.bms-theme.border-top {
  border-top: 1px solid $neutral-850 !important;
}

.bms-theme-content .border-top {
  border-top: 1px solid $neutral-850 !important;
}

.position-relative {
  position: relative;
}

.vertical-divider {
  height: 40px;
}

.clip-text-overflow {
  text-overflow: initial !important;
}
